// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-us-js": () => import("./../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-careers-js": () => import("./../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-construction-js": () => import("./../../src/pages/construction.js" /* webpackChunkName: "component---src-pages-construction-js" */),
  "component---src-pages-contact-us-js": () => import("./../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-events-js": () => import("./../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-life-at-bh-js": () => import("./../../src/pages/lifeAtBH.js" /* webpackChunkName: "component---src-pages-life-at-bh-js" */),
  "component---src-pages-solutions-js": () => import("./../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */)
}

